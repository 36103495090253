/* global mz */
import { round } from '@/helpers'

export default {
  data() {
    return {
      uploader: null,
      uploadOptions: {
        url: `${mz.config.search.url}/search/upload`,
        paramName: 'file',
        headers: {
          Authorization: `Bearer ${mz.config.search.key}`
        },
        maxFilesize: 1,
        acceptedFiles: {
          extensions: ['image/jpeg', 'image/png', 'image/gif'],
          message: 'Only image files are accepted'
        },
        thumbnailWidth: null, // set to null to preserve original image size and aspect ratio
        thumbnailHeight: null // same as above
      }
    }
  },
  methods: {
    round,
    uploadOnInit(uploader) {
      this.uploader = uploader
    },
    uploadProgress(progress, totalBytes, bytesSent) {
      // placeholder
    },
    uploadAddedFile(file) {
      if (this.uploader.files.length > 1) {
        this.uploader.files = this.uploader.files.slice(1)
      }
    }
  }
}
