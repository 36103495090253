<template>
  <div :class="['mz-banner', zone]" @click="trackClick()">
    <slot />
    <div v-if="visible" v-html="html" />
  </div>
</template>

<script>
/* global mz */
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'MzAutocompleteBanner',
  props: {
    zone: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState('search', ['queryId']),
    ...mapGetters('search', ['getAutocompleteBannerByZone']),
    id() {
      const zone = this.getAutocompleteBannerByZone(this.zone)
      if (zone) {
        return zone.id
      }
      return null
    },
    html() {
      const zone = this.getAutocompleteBannerByZone(this.zone)
      if (zone) {
        return zone.html
      }
      return ''
    }
  },
  methods: {
    trackClick() {
      if (mz && mz.track) {
        if (this.id === null) {
          return
        }
        mz.track('banner', {
          queryId: this.queryId,
          zone: `${this.zone}`,
          id: this.id
        })
      }
    }
  }
}
</script>
