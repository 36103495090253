import { stripHtml, formatMoney } from '@/helpers'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      currentIndex: 0,
      currentVariant: null
    }
  },
  computed: {
    ...mapState('search', ['config']),
    variant() {
      return this.item.variants ? this.item.variants[0] : {}
    },
    available() {
      return this.item.variants.find((x) => x.availableForSale)
    },
    sold_out() {
      return !this.available
    },
    on_sale() {
      return this.item.variants.find(
        (x) =>
          x.availableForSale &&
          parseFloat(x.price) < parseFloat(x.compareAtPrice)
      )
    },
    price() {
      return this.item.price
    },
    compare_at() {
      // keep only variants that contain compareAtPrice property
      var filtered = this.item.variants.filter((x) => x.compareAtPrice)
      return filtered.reduce((prev, curr) =>
        parseFloat(prev.compareAtPrice) < parseFloat(curr.compareAtPrice)
          ? prev
          : curr
      )
    },
    compare_at_price() {
      return this.compare_at.compareAtPrice
    },
    price_varies() {
      const prices = []
      this.item.variants.forEach(function (x) {
        if (!prices.includes(x.price)) prices.push(x.price)
      })
      return prices.length > 1
    },
    compare_at_price_varies() {
      const prices = []
      this.item.variants.forEach(function (x) {
        if (!prices.includes(x.compareAtPrice)) prices.push(x.compareAtPrice)
      })
      return prices.length > 1
    }
  },
  methods: {
    onVariantChange({ index, variant }) {
      this.currentIndex = index
      this.currentVariant = variant
    },
    formatImageSrc(src, { size, scale, crop, format } = {}) {
      src = src || ''
      const match = src.match(/(.*\/products\/)(.*)\.(?=[^.]*)([^?]*)(.*)/i)
      if (match === null) {
        return src
      }
      let prefix = match[1]
      let name = match[2]
      let ext = match[3]
      let suffix = match[4]

      // concatenate src from parts
      let result = `${prefix}${name}`
      if (size) {
        result = `${result}_${size}`
      }
      if (crop) {
        result = `${result}_crop_${crop}`
      }
      if (scale && (scale == 2 || scale == 3)) {
        result = `${result}@${scale}x`
      }
      if (format) {
        if (format == 'jpg') {
          result = `${result}.${ext}`
        }
        if (format == 'pjpg') {
          result = `${result}.progressive.${ext}`
        }
      } else {
        result = `${result}.${ext}`
      }
      return `${result}${suffix}`
    },
    imageSrc(options) {
      let src = this.item.image
      if (this.inner && this.inner.image) {
        src = this.inner.image
      }
      if (this.currentVariant && this.currentVariant.image) {
        src = this.currentVariant.image
      }
      return this.formatImageSrc(src, options)
    },
    secondarySrc(options) {
      let src = this.inner
        ? this.inner.secondary || this.item.secondary
        : this.item.secondary
      return this.formatImageSrc(src, options)
    },
    currency(value) {
      return '<span class="money">' + formatMoney(value) + '</span>'
    },
    stripHtml(value) {
      return stripHtml(value)
    }
  }
}
