<template>
  <ch-drawer
    location="right"
    area="65%"
    custom-class="mz-image-search"
    :visible.sync="imageSearch"
    @opened="opened"
  >
    <template v-if="!first">
      <mz-image-upload
        v-on:upload-complete="imageUploadComplete"
      ></mz-image-upload>
    </template>
    <template v-else>
      <div class="mz-image-search-container">
        <div class="mz-image-search-left">
          <div class="mz-image-search-left-container">
            <div class="mz-image-container">
              <img
                v-if="first"
                :src="first.url"
                class="mz-image-search-image"
                :class="{ 'mz-active': active === 1 }"
              />
              <img
                v-if="second"
                :src="second.url"
                class="mz-image-search-image mz-second"
                :class="{ 'mz-active': active !== 1 }"
              />
            </div>
            <template v-for="(b, idx) of boxes">
              <div
                :key="idx"
                @click="changeSelected(idx)"
                :class="getBoxClass(b)"
                :style="getBoxStyle(b)"
              ></div>
            </template>
            <mz-button-upload
              v-on:upload-complete="imageUploadComplete"
            ></mz-button-upload>
          </div>
          <div style="height: 50px">other content</div>
          <div style="height: 50px">other content</div>
          <div style="height: 50px">other content</div>
          <div style="height: 50px">other content</div>
          <div style="height: 50px">other content</div>
          <div style="height: 50px">other content</div>
          <div style="height: 50px">other content</div>
          <div style="height: 50px">other content</div>
          <div style="height: 50px">other content</div>
        </div>
        <div class="mz-image-search-right">
          <h3 v-if="first">first image: {{ first.name }}</h3>
          <h3 v-if="second">second image: {{ second.name }}</h3>
          Search results here ... Search results here ... Search results here
          ...
        </div>
      </div>
    </template>
  </ch-drawer>
</template>

<script>
import MzImageUpload from './MzImageUpload'
import MzButtonUpload from './MzButtonUpload'

export default {
  components: {
    'mz-image-upload': MzImageUpload,
    'mz-button-upload': MzButtonUpload
  },
  props: {
    selector: {
      type: String,
      required: false,
      default: 'input[name="q-image-search"]'
    }
  },
  name: 'MzImageSearch',
  data() {
    return {
      imageSearch: false,
      active: 0,
      first: undefined,
      second: undefined,
      boxes: undefined
    }
  },
  mounted() {
    const itemList = document.querySelectorAll(this.selector)
    let inputs = []
    Array.from(itemList).forEach(function (item) {
      inputs.push(item)
    })
    for (const match of inputs) {
      match.addEventListener('click', this.showImageSearch)
    }
  },
  methods: {
    showImageSearch() {
      this.imageSearch = true
    },
    changeSelected(idx) {
      this.boxes.forEach((b) => {
        b.selected = false
      })
      this.boxes[idx].selected = true
    },
    getBoxClass(b) {
      if (b.selected) {
        return 'mz-image-search-box'
      }
      return 'mz-image-search-circle'
    },
    getBoxStyle(b) {
      if (b.selected) {
        return {
          left: `${(b.box.cx - b.box.w / 2) * 100}%`,
          top: `${(b.box.cy - b.box.h / 2) * 100}%`,
          width: b.box.w * 100 + '%',
          height: b.box.h * 100 + '%'
        }
      }
      return {
        left: `calc(${b.box.cx * 100}% - 18px)`,
        top: `calc(${b.box.cy * 100}% - 18px)`,
        width: '36px',
        height: '36px'
      }
    },
    opened() {
      this.$emit('opened')
    },
    imageUploadComplete({ file, name, url, boxes }) {
      const image = { name, url: file.dataUrl }
      if (this.active === 1) {
        this.second = image
        this.active = 2
      } else {
        this.first = image
        this.active = 1
      }
      this.boxes = boxes
    }
  },
  computed: {},
  beforeDestroy() {}
}
</script>

<style>
.ch-drawer.ch-drawer--right.mz-image-search {
  max-width: unset;
}
@media screen and (max-width: 768px) {
  .ch-drawer.ch-drawer--right.mz-image-search {
    width: 100% !important;
  }
}
.mz-image-search .ch-drawer__content {
  padding: 10px 0 0 0;
}
.mz-image-search-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.mz-image-search-left {
  min-height: calc(100vh - 45px);
  max-height: calc(100vh - 45px);
  display: block;
  width: 28%;
  max-width: 400px;
  overflow-y: scroll;
}
.mz-image-search-left-container {
  position: relative;
  width: auto;
  height: auto;
  display: inline-block;
  overflow: hidden;
}
.mz-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.mz-image-search-image {
  max-width: 100%;
  opacity: 0;
  transition: opacity 1s;
}
.mz-image-search-image.mz-second {
  margin-left: -100%;
}
.mz-image-search-image.mz-active {
  opacity: 1;
}
.mz-image-search-right {
  max-height: calc(100vh - 45px);
  display: block;
  width: 72%;
  padding-left: 40px;
  padding-right: 40px;
  overflow-y: auto;
}
.mz-image-search-circle {
  user-select: none;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #fff;
  z-index: 1;
  transition: height 0.5s, width 0.5s, left 0.5s, top 0.5s, border 0.2s,
    background-color 0.2s, border-radius 0.5s, transform 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff80;
}
.mz-image-search-circle:active {
  transform: scale(0.9);
}
.mz-image-search-circle:hover {
  background-color: #ffffffaa;
}
.mz-image-search-circle::before {
  font-size: 22px;
  color: #fff;
  content: '+';
  transition: opacity 0.5s;
}
.mz-image-search-box {
  user-select: none;
  display: block;
  position: absolute;
  border-radius: 15px;
  border: 1px solid #fff;
  box-shadow: 0 0 0 9999em #0006;
  transition: height 0.5s, width 0.5s, left 0.5s, top 0.5s, border 0.2s,
    background-color 0.2s, border-radius 0.5s, transform 0.2s;
}
</style>
