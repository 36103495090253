import { mapState, mapGetters } from 'vuex'

export default {
  name: 'mz-component',
  methods: {
    changeSort(sort) {
      this.$store.dispatch('search/setSort', sort)
      if (this.$cookies) this.$cookies.set('sort', sort, { sameSite: 'strict' })
      this.changePage(1)
    },
    changePage(page) {
      this.$store.dispatch('search/setPage', page)
      if (this.$bus) this.$bus.emit('run-query')
    },
    highlight(val, search) {
      if (search) {
        const check = new RegExp(search, 'ig')
        return val.toString().replace(check, function (matchedText) {
          return '<span class="mz-highlight">' + matchedText + '</span>'
        })
      }
      return val
    }
  },
  computed: {
    ...mapGetters('search', ['getBannerByZone']),
    ...mapState('search', [
      'primaryKey',
      'pageId',
      'queryId',
      'keyword',
      'page',
      'sort',
      'pageSize',
      'selections',
      'pagination',
      'aggregations',
      'hits',
      'overlay'
    ])
  }
}
