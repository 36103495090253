<template>
  <ch-drawer
    location="top"
    area="85%"
    custom-class="mz-full-wrapper"
    :close-visible="false"
    :visible.sync="autocomplete"
    @opened="opened"
  >
    <div
      v-if="autocomplete"
      :class="['mz-autocomplete', config.layout === 'reverse' ? 'reverse' : '']"
      @keydown="keyHandler"
    >
      <div class="mz-full-header">
        <div class="mz-full-logo">
          <img
            src="https://cdn.shopify.com/s/files/1/0612/2881/0391/files/logo-200x200.png"
            class="mz-full-logo-mobile"
            alt="logo"
          />
          <img
            src="https://cdn.shopify.com/s/files/1/0612/2881/0391/files/logo-200x200.png"
            class="mz-full-logo-desktop"
            alt="logo"
          />
        </div>
        <div class="mz-full-input-wrapper">
          <div class="mz-full-input">
            <input
              ref="search"
              v-model="search"
              type="text"
              :name="queryParam"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              :placeholder="placeholder"
              @input="changeSuggest"
              @keydown="keyDown"
              @keyup.stop="doSuggest"
              @keypress.enter.prevent="showResults"
              @focus="onFocus"
            />
            <div class="mz-full-clear" v-if="search">
              <a
                href="#"
                tabindex="0"
                aria-label="clear search keyword"
                @click.prevent="onClear"
              >
                {{ $t('Clear') }}
              </a>
            </div>
            <div class="mz-full-search">
              <a
                href="#"
                tabindex="0"
                aria-label="run search"
                @click.prevent="showResults"
              >
                <fa :icon="faSearch" />
              </a>
            </div>
          </div>
        </div>
        <div class="mz-full-close">
          <a
            href="#"
            aria-label="close autocomplete"
            @click.prevent="toggleAutocomplete(false)"
          >
            <fa :icon="faTimes" />
            <div class="mz-full-close-esc">esc</div>
          </a>
        </div>
      </div>
      <div class="mz-body">
        <div class="mz-left">
          <div
            v-if="suggestItems && suggestItems.total"
            class="mz-full-view-all"
          >
            <a
              href="#"
              :aria-label="
                $t('View all {total} results', { total: suggestItems.total })
              "
              @click.stop.prevent="showResults"
              v-html="
                $t('View all {total} results', { total: suggestItems.total })
              "
            ></a>
          </div>
          <template v-for="(section, index) in config.sideAreaSections">
            <div
              v-if="
                section.visible === true &&
                section.modal === 'modal-popular' &&
                suggestResult &&
                suggestResult._ &&
                suggestResult._.items &&
                suggestResult._.items.length > 0
              "
              :key="`label-${index}`"
              class="mz-suggest-header"
            >
              <template v-if="query() !== ''">
                {{ section.config.label }}
              </template>
              <template v-if="query() === '' && config.popularOnFocus">
                {{ section.config.labelPopular }}
              </template>
            </div>
            <div
              v-if="
                section.visible === true &&
                section.modal === 'modal-popular' &&
                suggestResult &&
                suggestResult._ &&
                suggestResult._.items &&
                suggestResult._.items.length > 0
              "
              :key="index"
              class="mz-suggest-list"
            >
              <div
                v-for="item in suggestResult._.items"
                :key="item.text"
                tabindex="0"
                :class="{
                  'mz-suggest-item': true,
                  'mz-active': currentSuggestion === item.text
                }"
                @click.prevent="clickSearch(item.text)"
                @mouseenter.prevent="
                  if (queryOnHover) setCurrentSuggestion(item.text)
                "
                @keydown.13.prevent="clickSearch(item.text)"
              >
                {{ item.text }}
              </div>
            </div>
            <div
              v-if="
                section.visible === true &&
                section.modal === 'modal-side-custom' &&
                section.config &&
                section.config.html
              "
              :key="index"
              class="mz-suggest-list"
              v-html="section.config.html"
            />
            <div
              v-if="
                section.visible === true &&
                section.modal === 'modal-facets' &&
                section.config.facets &&
                facetSuggestions
              "
              :key="index"
              class="mz-suggest-list"
            >
              <div v-for="(group, idx) in section.config.facets" :key="idx">
                <b
                  v-if="
                    suggestResult &&
                    suggestResult[group.facet.name] &&
                    suggestResult[group.facet.name].items &&
                    suggestResult[group.facet.name].items.length > 0
                  "
                >
                  {{ group.label }}
                </b>
                <div
                  v-if="
                    suggestResult &&
                    suggestResult[group.facet.name] &&
                    suggestResult[group.facet.name].items &&
                    suggestResult[group.facet.name].items.length > 0
                  "
                  :key="index"
                  class="mz-suggest-list"
                >
                  <div
                    v-for="item in suggestResult[group.facet.name].items"
                    :key="item.original"
                    tabindex="0"
                    class="mz-suggest-item"
                    @click.prevent="clickFacet(group.facet, item.original)"
                    @keydown.13.prevent="clickFacet(group.facet, item.original)"
                  >
                    {{ item.original | value }}
                  </div>
                </div>
              </div>
            </div>
            <b
              v-if="
                section.visible === true &&
                section.modal === 'modal-recent' &&
                suggestRecent &&
                suggestRecent.length > 0
              "
              :key="`recent-${index}`"
            >
              {{ section.config.label }}
            </b>
            <div
              v-if="
                section.visible === true &&
                section.modal === 'modal-recent' &&
                suggestRecent &&
                suggestRecent.length > 0
              "
              :key="index"
              class="mz-suggest-list"
            >
              <div
                v-for="item in suggestRecent"
                :key="item.text"
                tabindex="0"
                :class="{
                  'mz-suggest-item': true
                }"
                @click.prevent="clickRecent(item.text)"
                @keydown.13="clickRecent(item.text)"
              >
                {{ item.text }}
                <a
                  class="mz-suggest-delete"
                  href="#"
                  aria-label="delete"
                  @click.stop.prevent="deleteSearch(item.text)"
                  @keydown.13.prevent="deleteSearch(item.text)"
                  v-text="$t('del')"
                >
                </a>
              </div>
            </div>
          </template>
        </div>
        <div class="mz-right">
          <template v-for="(section, index) in config.mainAreaSections">
            <template
              v-if="
                section.visible === true && section.modal === 'modal-top-items'
              "
            >
              <b v-if="suggestItems.total" :key="`text-${index}`">
                <template v-if="query() !== ''">
                  {{ section.config.label }}
                  <kbd>
                    {{ currentSuggestion || complete || query() || 'empty' }}
                  </kbd>
                </template>
                <template v-if="query() === '' && config.popularOnFocus">
                  {{ section.config.labelPopular }}
                </template>
              </b>
              <span
                v-if="suggestItems.total"
                :key="`summary-${index}`"
                class="mz-summary-count mz-hidden-xs"
                style="float: right"
              >
                {{
                  $t('SummaryCount', {
                    total: suggestItems.total,
                    took: suggestItems.took
                  })
                }}
              </span>
              <template v-if="isGridView">
                <mz-autocomplete-banner
                  zone="results-top-1"
                ></mz-autocomplete-banner>
                <mz-autocomplete-banner
                  zone="results-top-2"
                ></mz-autocomplete-banner>
                <div v-if="suggestItems.items" class="mz-autocomplete-grid">
                  <template v-for="(hit, idx) of suggestItems.items">
                    <slot
                      name="grid-item"
                      :hit="hit"
                      :index="idx"
                      :primary-key="primaryKey"
                      :config="config"
                      :is-hidden="isHidden"
                      :on-item-click="onItemClick"
                    >
                      <mz-grid-item
                        :inner="hit.inner"
                        :item="hit.item"
                        :score="hit.score"
                        :index="index"
                      />
                    </slot>
                  </template>
                </div>
                <mz-autocomplete-banner
                  zone="results-bottom-1"
                ></mz-autocomplete-banner>
                <mz-autocomplete-banner
                  zone="results-bottom-2"
                ></mz-autocomplete-banner>
              </template>
            </template>
            <div
              v-if="
                section.visible === true &&
                section.modal === 'modal-main-custom'
              "
              :key="index"
              class="mz-row"
              v-html="section.config.html"
            />
          </template>
        </div>
      </div>
    </div>
  </ch-drawer>
</template>

<script>
import Component from '../mixins/component.js'
import BaseSearchBox from '../mixins/baseSearchBox.js'
import MzGridItem from '@/custom/HitGridItem.vue'
import MzAutocompleteBanner from '@/custom/MzAutocompleteBanner.vue'

export default {
  name: 'MzSlideAutocomplete',
  components: {
    MzGridItem,
    MzAutocompleteBanner
  },
  mixins: [Component, BaseSearchBox],
  props: {
    matches: {
      array: HTMLInputElement,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      search: '',
      includeBanners: true
    }
  },
  computed: {
    input() {
      return this.$refs.search
    },
    facetSuggestions() {
      if (!this.suggestResult) {
        return false
      }
      const section = this.config.mainAreaSections.find(
        (x) => x.modal === 'modal-facets'
      )
      if (!section || !section.config || !section.config.facets) {
        return false
      }
      for (const group of section.config.facets) {
        if (
          this.suggestResult[group.facet.name] &&
          this.suggestResult[group.facet.name].items &&
          this.suggestResult[group.facet.name].items.length > 0
        ) {
          return true
        }
      }
      return false
    },
    view: {
      get() {
        if (this.internalView) {
          return this.internalView
        }
        return 'grid'
      },
      set(val) {
        this.internalView = val
        if (this.$cookies)
          this.$cookies.set('autocomplete-view', val, { sameSite: 'strict' })
      }
    },
    isLoaded() {
      return this.suggestItems.items
    },
    isGridView() {
      return this.view === 'grid' && this.isLoaded
    }
  },
  methods: {
    showResults() {
      if (this.currentSuggestion) {
        let keyword = this.currentSuggestion
        // track suggestion click event
        const event = {
          type: 'suggestion',
          keyword: this.query(),
          complete: '',
          suggestion: keyword
        }
        this.processClick(event, keyword)
      } else {
        this.doEnter()
      }
    },
    query() {
      return this.search
    },
    opened() {
      this.$refs.search.focus()
    },
    onClear() {
      this.search = ''
      this.$refs.search.focus()
      this.runAutocomplete(this.query(), false, this.resultsCount)
    },
    toggleAutocomplete(show) {
      this.autocomplete = show
    },
    onFocus(event) {
      event.preventDefault()
      if (this.query() !== '' || this.config.popularOnFocus) {
        this.autocomplete = true
        if (this.isLoaded) {
          return
        }
        this.runAutocomplete(this.query(), false, this.resultsCount)
      }
    }
  },
  beforeCreate() {
    const param = this.$options.propsData.queryParam
    if (param === undefined) {
      return
    }
    this.$store.dispatch('search/setQueryParam', param, { root: true })
  },
  mounted() {
    for (const match of this.matches) {
      match.addEventListener('click', this.onFocus)
    }
    this.emitChange(this.keyword)
  }
}
</script>

<style>
.ch-drawer.ch-drawer--top.mz-full-wrapper {
  height: 85% !important;
  max-width: unset;
}
@media (max-width: 768px) {
  .ch-drawer.ch-drawer--top.mz-full-wrapper {
    height: 100% !important;
  }
}
.mz-full-wrapper .ch-drawer__content {
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin-bottom: 10px;
  padding: 0;
}
.mz-full-wrapper .mz-autocomplete {
  position: relative;
  width: 100%;
  max-width: 1200px;
}
@media (max-width: 1200px) {
  .mz-full-wrapper .mz-autocomplete {
    margin: 0 10px;
  }
}

.mz-full-wrapper .mz-autocomplete .mz-body {
  height: 100%;
  margin-top: 15px;
}
@media (max-width: 768px) {
  .mz-full-wrapper .mz-autocomplete .mz-body {
    height: auto;
    max-height: 100%;
    overflow-y: auto;
  }
}
.mz-full-header {
  display: flex;
  gap: 20px;
}
.mz-full-header .mz-full-logo {
  max-height: 50px;
}
.mz-full-logo-desktop {
  display: block;
}
.mz-full-logo-mobile {
  display: none;
}
@media (max-width: 768px) {
  .mz-full-logo-desktop {
    display: none;
  }
  .mz-full-logo-mobile {
    display: block;
  }
}
.mz-full-header .mz-full-logo > img {
  max-height: 50px;
}
.mz-full-header .mz-full-input-wrapper {
  flex-grow: 1;
}
.mz-full-header .mz-full-input {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #666;
}
.mz-full-header .mz-full-input input {
  height: 40px;
  border: 0;
  padding: 0;
  width: 100%;
  outline: none;
}
@media (min-width: 769px) {
  .mz-full-header .mz-full-input input {
    font-size: 22px;
  }
}
.mz-full-header .mz-full-search {
  position: absolute;
  font-size: 18px;
  top: 10px;
  right: 4px;
}
.mz-full-header .mz-full-close {
  font-size: 32px;
  margin-top: 5px;
}
.mz-full-header .mz-full-close a {
  color: #666;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mz-full-header .mz-full-close-esc {
  font-size: 9px;
  margin-top: -6px;
}

.mz-full-header .mz-autocomplete-close {
  font-size: 45px;
}
.mz-full-clear {
  position: absolute;
  text-transform: lowercase;
  top: 14px;
  right: 28px;
  font-size: 12px;
}
.mz-full-view-all {
  text-align: right;
  font-weight: 600;
  margin-bottom: 5px;
}
.mz-suggest-header {
  font-weight: 600;
}
.mz-full-wrapper .mz-autocomplete .mz-left,
.mz-full-wrapper .mz-autocomplete.reverse .mz-left,
.mz-full-wrapper .mz-autocomplete .mz-right,
.mz-full-wrapper .mz-autocomplete.reverse .mz-right {
  border-radius: 0;
  max-height: initial;
}

@media (min-width: 769px) {
  .mz-full-wrapper .mz-autocomplete .mz-left {
    border-right: 1px solid #ccc;
  }
  .mz-full-wrapper .mz-autocomplete.reverse .mz-left {
    border-left: 1px solid #ccc;
    border-right: none;
  }
}
div.ch-drawer__mask:empty {
  display: initial;
}
</style>
