<template>
  <base-recommendations v-bind="$attrs">
    <template
      #recommendation-item="{
        template,
        html,
        item,
        queryId,
        source,
        layout,
        index,
        primaryKey,
        recommendationClick
      }"
    >
      <recommendation-html-item
        v-if="html"
        :template="template"
        :html="html"
        :item="item"
        :query-id="queryId"
        :source="source"
        :layout="layout"
        :index="index"
        :primary-key="primaryKey"
        @recommendation-click="recommendationClick"
      ></recommendation-html-item>
      <recommendation-item
        v-else
        :template="template"
        :item="item"
        :query-id="queryId"
        :source="source"
        :layout="layout"
        :index="index"
        :primary-key="primaryKey"
        @recommendation-click="recommendationClick"
      ></recommendation-item>
    </template>
  </base-recommendations>
</template>

<script>
import { BaseRecommendations } from '@mezereon/ui-components-vue'
import RecommendationItem from './RecommendationItem.vue'
import RecommendationHtmlItem from './RecommendationHtmlItem.vue'

export default {
  name: 'MzShopifyRecommendations',
  components: {
    BaseRecommendations,
    RecommendationItem,
    RecommendationHtmlItem
  },
  inheritAttrs: false
}
</script>
