export const namespaced = true

export const state = () => ({
  size: 15,
  searches: []
})

function FilterSearches(searches, keyword) {
  return searches.filter((item) => {
    const toDelete = item.complete || item.suggestion || item.keyword || ''
    return toDelete.toLowerCase() !== keyword.toLowerCase()
  })
}

export const mutations = {
  ADD_SEARCH(state, payload) {
    const keyword =
      payload.complete || payload.suggestion || payload.keyword || ''

    // do not add empty keyword to history
    if (keyword == '') {
      return
    }

    state.searches = FilterSearches(state.searches, keyword)
    // fixed size array
    if (state.searches.length >= state.size) {
      state.searches.shift()
    }
    state.searches.push(payload)
  },
  DELETE_SEARCH(state, keyword) {
    state.searches = FilterSearches(state.searches, keyword)
  }
}

export const actions = {
  addSearch({ commit }, payload) {
    commit('ADD_SEARCH', payload)
  },
  deleteSearch({ commit }, payload) {
    commit('DELETE_SEARCH', payload)
  }
}

export const getters = {
  recentSearches: (state) => (count) => {
    const map = new Map()
    for (const search of state.searches.slice(0).reverse()) {
      let text = search.complete || search.suggestion || search.keyword
      text = text.toLowerCase()
      map.set(text, { text })
      if (map.size >= count) {
        break
      }
    }
    const recent = []
    for (const value of map.values()) {
      recent.push(value)
    }
    return recent
  }
}

export const history = {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
