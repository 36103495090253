/* global mz */
import axios from 'redaxios'

const Axios = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${mz.config.search.key}`
  },
  baseURL: mz.config.search.url
})

export default {
  Axios,
  install(Vue, options) {
    Vue.prototype.$axios = Axios
  }
}
