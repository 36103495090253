export const namespaced = true

export const state = () => ({
  searchKey: '',
  apiUrl: ''
})

export const mutations = {
  SET_SEARCH_KEY(state, data) {
    state.searchKey = data
  },
  SET_API_URL(state, data) {
    state.apiUrl = data
  }
}

export const actions = {
  setSearchKey({ commit }, payload) {
    commit('SET_SEARCH_KEY', payload)
  },
  setApiUrl({ commit }, payload) {
    commit('SET_API_URL', payload)
  }
}

export const getters = {}

export const api = {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
