<template>
  <form role="form">
    <vue-clip
      ref="vueClip"
      :options="uploadOptions"
      :on-complete="uploadComplete"
      :on-init="uploadOnInit"
      :on-added-file="uploadAddedFile"
      :on-total-progress="uploadProgress"
      class="mz-button-upload"
    >
      <template slot="clip-uploader-action" slot-scope="params">
        <div v-bind:class="{ 'is-dragging': params.dragging }">
          <div class="mz-image-search-upload dz-message">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M17.5 12a5.5 5.5 0 1 1 0 11a5.5 5.5 0 0 1 0-11Zm0 2l-.09.008a.5.5 0 0 0-.402.402L17 14.5V17h-2.502l-.09.009a.5.5 0 0 0-.402.402l-.008.09l.008.09a.5.5 0 0 0 .402.402l.09.008H17v2.503l.008.09a.5.5 0 0 0 .402.402l.09.008l.09-.008a.5.5 0 0 0 .402-.402l.008-.09V18h2.504l.09-.007a.5.5 0 0 0 .402-.402l.008-.09l-.008-.09a.5.5 0 0 0-.403-.402l-.09-.008H18v-2.5l-.008-.09a.5.5 0 0 0-.402-.403L17.5 14ZM13.925 2.504a2.25 2.25 0 0 1 1.94 1.11l.814 1.387h2.071A3.25 3.25 0 0 1 22 8.25v4.56a6.52 6.52 0 0 0-1.499-1.077L20.5 8.25a1.75 1.75 0 0 0-1.75-1.75h-2.5a.75.75 0 0 1-.647-.37l-1.032-1.757a.75.75 0 0 0-.646-.37h-3.803a.75.75 0 0 0-.574.268l-.065.09L8.39 6.142a.75.75 0 0 1-.639.358h-2.5A1.75 1.75 0 0 0 3.5 8.25v9.5c0 .966.784 1.75 1.75 1.75h6.063c.173.533.412 1.037.709 1.5H5.25A3.25 3.25 0 0 1 2 17.75v-9.5A3.25 3.25 0 0 1 5.25 5h2.08l.875-1.424a2.25 2.25 0 0 1 1.917-1.073h3.803ZM12 8a4.502 4.502 0 0 1 4.283 3.114c-.5.095-.98.247-1.433.449A2.999 2.999 0 0 0 9 12.5c0 1.43 1 2.625 2.338 2.927a6.446 6.446 0 0 0-.31 1.467A4.501 4.501 0 0 1 12 8.001Z"
              ></path>
            </svg>
          </div>
        </div>
      </template>

      <template slot="clip-uploader-body" slot-scope="props">
        <div v-for="file in props.files" :key="file.name">
          <div class="mz-error-indicator" v-if="file.status == 'error'">
            {{ file.errorMessage }}
          </div>
          <div class="mz-file-progress" v-if="file.status == 'added'">
            <div
              class="mz-progress-indicator"
              v-bind:style="{ width: round(file.progress, 0) + '%' }"
            >
              <span>{{ round(file.progress, 0) }}%</span>
            </div>
          </div>
        </div>
      </template>
    </vue-clip>
  </form>
</template>

<script>
import Vue from 'vue'
import VueClip from 'vue-clip'
import Upload from '@/mixins/upload.js'

Vue.use(VueClip)

export default {
  mixins: [Upload],
  methods: {
    uploadComplete(file, status, xhr) {
      if (status === 'canceled') {
        console.log(xhr.responseText)
        return
      }
      if (status === 'error') {
        console.log(xhr.responseText)
        return
      }
      if (xhr.status !== 200) {
        console.log(xhr.responseText)
        return
      }
      this.$emit('upload-complete', {
        file,
        ...JSON.parse(xhr.responseText)
      })
    }
  }
}
</script>

<style>
.mz-button-upload .mz-error-indicator {
  bottom: 10px;
  width: calc(100% - 55px) !important;
  background: linear-gradient(to right, #f45e44, #c5280d);
  position: absolute;
  color: #fff;
  border-radius: 6px;
  padding: 2px 5px;
  left: 3px;
  font-size: 12px;
  min-height: 40px;
  display: flex;
  align-items: center;
}
.mz-button-upload .mz-progress-indicator {
  background: linear-gradient(to right, #bbf7c5, #119408);
  height: 20px;
  min-width: 30px;
  border-radius: 6px;
  position: absolute;
  left: 2px;
  bottom: 18px;
  width: calc(100% - 55px) !important;
}
.mz-button-upload .mz-progress-indicator span {
  float: right;
  padding: 0 5px;
  color: #fff;
}
.mz-image-search-upload {
  user-select: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
  font-size: 24px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
  transition: background-color 0.2s, transform 0.1s;
}
.mz-image-search-upload:active {
  transform: scale(0.9);
}
.mz-image-search-upload:hover {
  background-color: #fff;
}
.mz-image-search-upload svg {
  transition: transform 0.2s;
}
.mz-image-search-upload svg:hover {
  transform: scale(1.05);
}
</style>
