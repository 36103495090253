<template>
  <v-runtime-template :template="gridItem"></v-runtime-template>
</template>

<script>
import { mapState } from 'vuex'
import VRuntimeTemplate from 'v-runtime-template'
import HitItem from '@/mixins/hitItem.js'
import MzReviews from './MzReviews'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    MzReviews,
    VRuntimeTemplate
  },
  mixins: [HitItem],
  computed: {
    ...mapState('search', ['gridItem'])
  }
}
</script>
