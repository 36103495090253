<template>
  <v-runtime-template
    :template="template"
    :class="{ disabled: hidden }"
  ></v-runtime-template>
</template>

<script>
import { AutocompleteItem } from '@mezereon/ui-components-vue'
import ExtendItem from '@/mixins/extendItem'
import MzReviews from './MzReviews'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    MzReviews
  },
  mixins: [AutocompleteItem, ExtendItem]
}
</script>
