<template>
  <div class="mz-reviews">
    <template v-for="index of 5">
      <span
        v-if="roundedHalf + 0.5 == index"
        :key="index"
        class="mz-star mz-star--half"
      >
        <component :is="star"></component>
      </span>
      <span
        v-else-if="roundedHalf >= index"
        :key="index"
        class="mz-star mz-star--full"
      >
        <component :is="star"></component>
      </span>
      <span v-else :key="index" class="mz-star mz-star--empty">
        <component :is="star"></component>
      </span>
    </template>
    <span class="mz-reviews-text" v-html="ratingText"></span>
  </div>
</template>

<script>
import MzStar1 from './MzStar1'
import MzStar2 from './MzStar2'
import MzStar3 from './MzStar3'

export default {
  name: 'MzReviews',
  components: {
    MzStar1,
    MzStar2,
    MzStar3
  },
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    count: {
      type: [Number, String],
      default: 0
    },
    pattern: {
      type: String,
      default: ''
    },
    star: {
      type: String,
      default: 'mz-star-1'
    }
  },
  computed: {
    roundedHalf() {
      return Math.round(this.value / 0.5) * 0.5
    },
    ratingText() {
      let pattern = this.pattern
      pattern = pattern.replace(new RegExp('{ratingValue}', 'g'), this.value)
      pattern = pattern.replace(new RegExp('{ratingCount}', 'g'), this.count)
      return pattern
    }
  }
}
</script>

<style>
.mz-reviews {
  display: inline-block;
  position: relative;
  height: 18px;
}
.mz-reviews .mz-reviews-text {
  margin-left: 5px;
}
.mz-reviews .mz-star {
  vertical-align: middle;
}
.mz-reviews .mz-star svg {
  height: 100%;
}
.mz-reviews .mz-star.mz-star--full svg {
  fill: #e7721b;
  stroke: #e7721b;
}

.mz-reviews .mz-star svg {
  stroke: #e7721b;
}

.mz-reviews .mz-star.mz-star--empty svg {
  fill: #f1f1f1;
}

.mz-reviews .mz-star.mz-star--half svg {
  fill: url(#mz-star-stop);
}

.mz-star-stop-full {
  stop-color: #e7721b;
}

.mz-star-stop-empty {
  stop-color: #f1f1f1;
}
</style>
