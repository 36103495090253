<template>
  <div
    ref="autocomplete"
    v-click-outside="clickOutside"
    :class="['mz-autocomplete', config.layout === 'reverse' ? 'reverse' : '']"
    @keydown="keyHandler"
  >
    <div ref="arrow" class="mz-arrow-wrapper">
      <div v-if="autocomplete && isLoaded" class="mz-arrow"></div>
    </div>
    <div v-if="autocomplete && isLoaded" class="mz-autocomplete-container">
      <div class="mz-body">
        <div class="mz-autocomplete-close">
          <a
            href="#"
            aria-label="close autocomplete"
            @click.prevent="autocomplete = false"
          >
            <fa :icon="faTimes" />
          </a>
        </div>
        <div class="mz-left">
          <template v-for="(section, index) in config.sideAreaSections">
            <b
              v-if="
                section.visible === true &&
                section.modal === 'modal-popular' &&
                suggestResult &&
                suggestResult._ &&
                suggestResult._.items &&
                suggestResult._.items.length > 0
              "
              :key="`label-${index}`"
            >
              <template v-if="query() !== ''">
                {{ section.config.label }}
              </template>
              <template v-if="query() === '' && config.popularOnFocus">
                {{ section.config.labelPopular }}
              </template>
            </b>
            <div
              v-if="
                section.visible === true &&
                section.modal === 'modal-popular' &&
                suggestResult &&
                suggestResult._ &&
                suggestResult._.items &&
                suggestResult._.items.length > 0
              "
              :key="index"
              class="mz-suggest-list"
            >
              <div
                v-for="item in suggestResult._.items"
                :key="item.text"
                tabindex="0"
                :class="{
                  'mz-suggest-item': true,
                  'mz-active': currentSuggestion === item.text
                }"
                @click.prevent="clickSearch(item.text)"
                @mouseenter.prevent="
                  if (queryOnHover) setCurrentSuggestion(item.text)
                "
                @keydown.13.prevent="clickSearch(item.text)"
              >
                {{ item.text }}
              </div>
            </div>
            <div
              v-if="
                section.visible === true &&
                section.modal === 'modal-side-custom'
              "
              :key="index"
              class="mz-suggest-list"
              v-html="section.config.html"
            />
            <div
              v-if="
                section.visible === true && section.modal === 'modal-facets'
              "
              :key="index"
              class="mz-suggest-list"
            >
              <div v-for="(group, idx) in section.config.facets" :key="idx">
                <b
                  v-if="
                    suggestResult &&
                    suggestResult[group.facet.name] &&
                    suggestResult[group.facet.name].items
                  "
                >
                  {{ group.label }}
                </b>
                <div
                  v-if="
                    suggestResult &&
                    suggestResult[group.facet.name] &&
                    suggestResult[group.facet.name].items &&
                    suggestResult[group.facet.name].items.length > 0
                  "
                  :key="index"
                  class="mz-suggest-list"
                >
                  <div
                    v-for="item in suggestResult[group.facet.name].items"
                    :key="item.original"
                    tabindex="0"
                    class="mz-suggest-item"
                    @click.prevent="clickFacet(group.facet, item.original)"
                    @keydown.13.prevent="clickFacet(group.facet, item.original)"
                  >
                    {{ item.original | value }}
                  </div>
                </div>
              </div>
            </div>
            <b
              v-if="
                section.visible === true &&
                section.modal === 'modal-recent' &&
                suggestRecent &&
                suggestRecent.length > 0
              "
              :key="`recent-${index}`"
            >
              {{ section.config.label }}
            </b>
            <div
              v-if="
                section.visible === true &&
                section.modal === 'modal-recent' &&
                suggestRecent &&
                suggestRecent.length > 0
              "
              :key="index"
              class="mz-suggest-list"
            >
              <div
                v-for="item in suggestRecent"
                :key="item.text"
                tabindex="0"
                :class="{
                  'mz-suggest-item': true
                }"
                @click.prevent="clickRecent(item.text)"
                @keydown.13="clickRecent(item.text)"
              >
                {{ item.text }}
                <a
                  class="mz-suggest-delete"
                  href="#"
                  aria-label="delete"
                  @click.stop.prevent="deleteSearch(item.text)"
                  @keydown.13.prevent="deleteSearch(item.text)"
                  v-text="$t('del')"
                >
                </a>
              </div>
            </div>
          </template>
        </div>
        <div class="mz-right">
          <template v-for="(section, index) in config.mainAreaSections">
            <template
              v-if="
                section.visible === true && section.modal === 'modal-top-items'
              "
            >
              <a
                v-if="toggleView"
                :key="`list-${index}`"
                href="#"
                :aria-label="$t('List')"
                :class="{
                  'mz-btn': true,
                  'mz-btn-sm': true,
                  'mz-active': view === 'list'
                }"
                @click.stop.prevent="changeView('list')"
              >
                <fa :icon="faBars" fw />
              </a>
              <a
                v-if="toggleView"
                :key="`grid-${index}`"
                href="#"
                :aria-label="$t('Grid')"
                style="margin-right: 5px"
                :class="{
                  'mz-btn': true,
                  'mz-btn-sm': true,
                  'mz-active': view === 'grid'
                }"
                @click.stop.prevent="changeView('grid')"
              >
                <fa :icon="faThLarge" fw />
              </a>
              <b v-if="suggestItems.total" :key="`text-${index}`">
                <template v-if="query() !== ''">
                  {{ section.config.label }}
                  <kbd>
                    {{ currentSuggestion || complete || query() || 'empty' }}
                  </kbd>
                </template>
                <template v-if="query() === '' && config.popularOnFocus">
                  {{ section.config.labelPopular }}
                </template>
              </b>
              <span
                v-if="suggestItems.total"
                :key="`summary-${index}`"
                class="mz-summary-count mz-hidden-xs"
                style="float: right"
              >
                {{
                  $t('SummaryCount', {
                    total: suggestItems.total,
                    took: suggestItems.took
                  })
                }}
              </span>
              <template v-if="isListView">
                <div class="mz-autocomplete-list">
                  <template v-for="(hit, idx) of suggestItems.items">
                    <slot
                      name="list-item"
                      :hit="hit"
                      :index="idx"
                      :primary-key="primaryKey"
                      :config="config"
                      :is-hidden="isHidden"
                      :on-item-click="onItemClick"
                    ></slot>
                  </template>
                </div>
              </template>
              <template v-if="isGridView">
                <div class="mz-autocomplete-grid">
                  <template v-for="(hit, idx) of suggestItems.items">
                    <slot
                      name="grid-item"
                      :hit="hit"
                      :index="idx"
                      :primary-key="primaryKey"
                      :config="config"
                      :is-hidden="isHidden"
                      :on-item-click="onItemClick"
                    ></slot>
                  </template>
                </div>
              </template>
            </template>
            <div
              v-if="
                section.visible === true &&
                section.modal === 'modal-main-custom'
              "
              :key="index"
              class="mz-row"
              v-html="section.config.html"
            />
          </template>
        </div>
      </div>
      <div class="mz-footer">
        <a
          href="#"
          :aria-label="
            $t('View all {total} results', { total: suggestItems.total })
          "
          @click.stop.prevent="doEnter"
          v-html="$t('View all {total} results', { total: suggestItems.total })"
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'
import { createPopper } from '@popperjs/core'
import Component from '../mixins/component.js'
import BaseSearchBox from '../mixins/baseSearchBox.js'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'BaseSearchBox',
  directives: {
    ClickOutside
  },
  mixins: [Component, BaseSearchBox],
  props: {
    input: {
      type: HTMLInputElement,
      required: true
    }
  },
  data() {
    return {
      popper: null,
      includeBanners: false
    }
  },
  computed: {
    toggleView() {
      return this.config.listView && this.config.gridView
    },
    view: {
      get() {
        if (this.internalView) {
          return this.internalView
        }
        let view = false
        if (this.$cookies) {
          view = this.$cookies.get('autocomplete-view')
        }
        if (view === 'list' && !this.config.listView) {
          return 'grid'
        }
        if (view === 'grid' && !this.config.gridView) {
          return 'list'
        }
        if (!view) {
          view = this.config.listView ? 'list' : 'grid'
        }
        return view
      },
      set(val) {
        this.internalView = val
        if (this.$cookies)
          this.$cookies.set('autocomplete-view', val, { sameSite: 'strict' })
      }
    },
    isLoaded() {
      return this.suggestItems.items && this.suggestItems.items.length > 0
    },
    isGridView() {
      return this.view === 'grid' && this.isLoaded
    },
    isListView() {
      return this.view === 'list' && this.isLoaded
    },
    finalPlacement() {
      if (this.placement) {
        return this.placement
      }
      return this.config.placement || 'bottom-start'
    },
    fallbackPlacements() {
      if (this.finalPlacement === 'bottom-start') {
        return ['bottom', 'bottom-end']
      }
      if (this.finalPlacement === 'bottom-end') {
        return ['bottom', 'bottom-start']
      }
      if (this.finalPlacement === 'bottom') {
        return ['bottom-end', 'bottom-start']
      }
      return ['bottom']
    }
  },
  watch: {
    keyword: {
      handler() {
        this.$emit('change', this.keyword)
        if (this.query() === '') {
          this.$store.dispatch('search/setComplete', '', { root: true })
        }
      },
      immediate: true
    },
    autocomplete: {
      async handler(val) {
        if (val) {
          await this.showPopper()
        } else {
          await this.hidePopper()
        }
      }
    }
  },
  beforeCreate() {
    const param = this.$options.propsData.queryParam
    if (param === undefined) {
      return
    }
    this.$store.dispatch('search/setQueryParam', param, { root: true })
  },
  beforeDestroy() {
    this.removePopper()
  },
  mounted() {
    const self = this

    this.popupItem = this.$el
    this.input.setAttribute('autocomplete', 'off')
    this.input.setAttribute('autocorrect', 'off')
    this.input.addEventListener('focus', self.onFocus)
    this.input.addEventListener('click', self.onFocus)
    this.input.addEventListener('keydown', self.keyDown)
    this.input.addEventListener('input', self.changeSuggest)
    this.input.addEventListener('keypress', (event) => {
      if (event.key === 'Enter') {
        self.doEnter()
        event.preventDefault()
      }
    })
    this.input.addEventListener('keuyup', (event) => {
      self.doSuggest()
      event.preventDefault()
    })
    this.$emit('change', this.keyword)
    this.setupPopper()
  },
  methods: {
    query() {
      return this.input.value
    },
    removePopper() {
      if (!this.popper) {
        return
      }
      this.popper.destroy()
    },
    setupPopper() {
      // do not setup when config is empty
      if (isEmpty(this.config)) {
        return
      }
      // remove if already created
      this.removePopper()

      const autocomplete = this.$refs.autocomplete
      const arrow = this.$refs.arrow
      const popperConfig = {
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: arrow,
              padding: 20
            }
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: this.fallbackPlacements,
              padding: 5
            }
          },
          {
            name: 'preventOverflow',
            options: {
              padding: 5,
              escapeWithReference: true
            }
          },
          {
            name: 'hide',
            options: {}
          }
        ],
        placement: this.finalPlacement
      }
      this.popper = createPopper(this.input, autocomplete, popperConfig)
    },
    async showPopper() {
      this.$refs.autocomplete.setAttribute('data-show', '')
      this.$emit('autocomplete-open')
      await this.popper.update()
    },
    clickOutside() {
      if (this.input.form && this.input.form.action.includes(this.searchUrl)) {
        if (this.input.form.contains(event.target)) return
      } else {
        if (event.target == this.input) return
      }
      this.autocomplete = false
    },
    async hidePopper() {
      this.$refs.autocomplete.removeAttribute('data-show')
      this.$emit('autocomplete-close')
    },
    onFocus() {
      const self = this
      if (this.delay > 0) {
        setTimeout(() => self.delayedOnFocus(), this.delay)
      } else {
        self.delayedOnFocus()
      }
    },
    delayedOnFocus() {
      if (this.query() !== '' || this.config.popularOnFocus) {
        this.autocomplete = true
        if (this.isLoaded) {
          return
        }
        this.runAutocomplete(this.query(), false, this.resultsCount)
      }
    }
  }
}
</script>

<style>
.mz-autocomplete {
  position: fixed;
  z-index: 9999998;
}

.mz-autocomplete-close {
  position: absolute;
  top: 14px;
  right: 17px;
  font-size: 17px;
  z-index: 9999999;
}

.mz-autocomplete .mz-autocomplete-container {
  display: block;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
  padding: 5px;
  top: 18px;
  border-radius: 0.3em;
  line-height: 1.42857143;
  background-color: #fff;
}

.mz-autocomplete .mz-btn.mz-btn-sm {
  border-color: transparent;
  background-color: transparent;
  padding: 3px 6px;
}

.mz-autocomplete .mz-btn.mz-btn-sm.mz-active {
  color: #333;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.mz-autocomplete .mz-arrow-wrapper {
  z-index: 100;
}

.mz-autocomplete .mz-arrow {
  position: absolute;
  top: 11px;
  box-shadow: 3px -3px 3px 0px rgba(0, 0, 0, 0.25);
  width: 14px;
  height: 14px;
  transform: rotate(-45deg);
  background-color: #fff;
}

.mz-autocomplete .mz-body {
  display: flex;
  width: 100%;
}

.mz-autocomplete.reverse .mz-body {
  flex-flow: row-reverse;
}

.mz-autocomplete .mz-left {
  width: 25%;
  min-width: 150px;
  display: inline-block;
  background-color: #f4f6f8;
  position: relative;
  border-top-left-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
  max-height: 500px;
  overflow-y: auto;
  z-index: 101;
}

@media (min-width: 769px) {
  .mz-autocomplete .mz-left {
    padding: 10px;
  }
}

.mz-autocomplete.reverse .mz-left {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  z-index: 101;
}

.mz-autocomplete .mz-right {
  display: inline-block;
  background-color: #fff;
  width: 75%;
  overflow-y: auto;
  max-height: 500px;
  border-top-right-radius: 0.3em;
  border-bottom-right-radius: 0.3em;
  z-index: 101;
}

.mz-autocomplete.reverse .mz-right {
  padding: 10px 10px 10px 0;
}

.mz-autocomplete.reverse .mz-right {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.3em;
  border-bottom-left-radius: 0.3em;
  z-index: 101;
}

.mz-autocomplete .mz-footer {
  padding: 6px 0 0 0;
  text-align: center;
}

.mz-autocomplete .mz-suggest-list {
  padding: 10px 0 10px 0;
}

.mz-autocomplete .mz-suggest-item {
  width: 100%;
  padding: 3px 3px 3px 10px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
  text-transform: capitalize;
}

.mz-autocomplete .mz-suggest-item:hover,
.mz-autocomplete .mz-suggest-item:focus {
  background-color: #bbc4e4;
}

.mz-autocomplete .mz-suggest-item.mz-active {
  background-color: #bbc4e4;
}

.mz-autocomplete .mz-row {
  margin-top: 10px;
}

.mz-autocomplete-list,
.mz-autocomplete-grid {
  margin-top: 10px;
}

.mz-autocomplete .mz-autocomplete-list-item.disabled,
.mz-autocomplete .mz-autocomplete-grid-item.disabled {
  opacity: 0.3;
}

.mz-autocomplete .mz-autocomplete-list-item:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.mz-autocomplete .mz-list-item-column {
  padding: 5px;
}

.mz-autocomplete .mz-autocomplete-grid-item:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.mz-autocomplete .mz-autocomplete-grid-item {
  padding: 5px 9px;
  max-width: 250px;
  flex-flow: column nowrap;
  align-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.mz-autocomplete .mz-autocomplete-grid-item:hover {
  background-color: auto;
}

.mz-autocomplete .mz-right::-webkit-scrollbar,
.mz-autocomplete .mz-left::-webkit-scrollbar {
  width: 5px;
  position: fixed;
  right: 0;
}

.mz-autocomplete .mz-right::-webkit-scrollbar-thumb,
.mz-autocomplete .mz-left::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
  transition: background-color 2s;
}

.mz-autocomplete .mz-right:hover::-webkit-scrollbar-thumb,
.mz-autocomplete .mz-left:hover::-webkit-scrollbar-thumb {
  background: #d9dbdc;
}

.mz-autocomplete .mz-right::-webkit-scrollbar-thumb:hover,
.mz-autocomplete .mz-left::-webkit-scrollbar-thumb:hover {
  background: #d9dbdc;
}

.mz-autocomplete .mz-right code,
.mz-autocomplete .mz-right kbd {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mz-autocomplete .mz-right kbd {
  padding: 0 4px;
  color: #fff;
  font-size: initial;
  background-color: #333;
  border-radius: 3px;
}
.mz-autocomplete .mz-suggest-delete {
  float: right;
  font-size: 0.8em;
  padding-top: 0.2em;
  text-transform: none;
}

@media (max-width: 768px) {
  .mz-autocomplete {
    width: 100%;
  }

  .mz-autocomplete .mz-autocomplete-container {
    width: calc(100vw - 10px);
  }

  .mz-autocomplete .mz-body {
    display: grid;
  }

  .mz-autocomplete .mz-left,
  .mz-autocomplete.reverse .mz-left {
    width: 100%;
    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .mz-autocomplete .mz-right,
  .mz-autocomplete.reverse .mz-right {
    max-height: 400px;
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0.3em;
    border-bottom-right-radius: 0.3em;
  }
}
</style>
