<template>
  <form role="form">
    <vue-clip
      ref="vueClip"
      :options="uploadOptions"
      :on-complete="uploadComplete"
      :on-init="uploadOnInit"
      :on-added-file="uploadAddedFile"
      :on-total-progress="uploadProgress"
      class="mz-image-upload"
    >
      <template slot="clip-uploader-action" slot-scope="params">
        <div class="dropzone" v-bind:class="{ 'is-dragging': params.dragging }">
          <div class="dz-message">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M18 13v7H4V6h5.02c.05-.71.22-1.38.48-2H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-5l-2-2zm-1.5 5h-11l2.75-3.53l1.96 2.36l2.75-3.54L16.5 18zm2.8-9.11c.44-.7.7-1.51.7-2.39C20 4.01 17.99 2 15.5 2S11 4.01 11 6.5s2.01 4.5 4.49 4.5c.88 0 1.7-.26 2.39-.7L21 13.42L22.42 12L19.3 8.89zM15.5 9a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z"
              />
            </svg>
            <div class="upload-title">SEARCH BY PHOTO</div>
            Click or drag & drop image here to upload
          </div>
        </div>
      </template>

      <template slot="clip-uploader-body" slot-scope="props">
        <div v-for="file in props.files" :key="file.name">
          <div class="mz-error-indicator" v-if="file.status == 'error'">
            {{ file.errorMessage }}
          </div>
          <div class="mz-file-progress" v-if="file.status == 'added'">
            <div
              class="mz-progress-indicator"
              v-bind:style="{ width: round(file.progress, 0) + '%' }"
            >
              <span>{{ round(file.progress, 0) }}%</span>
            </div>
          </div>
        </div>
      </template>
    </vue-clip>
  </form>
</template>

<script>
/* global mz */
import Vue from 'vue'
import VueClip from 'vue-clip'
import Upload from '@/mixins/upload.js'

Vue.use(VueClip)

export default {
  mixins: [Upload],
  methods: {
    uploadComplete(file, status, xhr) {
      if (status === 'canceled') {
        console.log(xhr.responseText)
        return
      }
      if (status === 'error') {
        console.log(xhr.responseText)
        return
      }
      this.$emit('upload-complete', { file, ...JSON.parse(xhr.responseText) })
    }
  }
}
</script>

<style>
.dz-message {
  user-select: none;
}
.dropzone .upload-title {
  font: 800 34px Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -1px;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 10px;
}
.dropzone.dz-clickable {
  cursor: pointer;
}
.dropzone {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='11' ry='11' stroke='%23A0A0A0FF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 10px;
  text-align: center;
  min-height: 120px;
  padding: 10px;
  margin: 10px;
}
.mz-image-upload .mz-file-progress {
  width: 100%;
  margin-top: 5px;
  background: #eee;
  border-radius: 6px;
  position: relative;
}
.mz-image-upload .mz-error-indicator {
  left: 10px;
  width: calc(100% - 20px) !important;
  background: linear-gradient(to right, #f45e44, #c5280d);
  position: absolute;
  color: #fff;
  border-radius: 6px;
  padding: 2px;
  text-align: center;
}
.mz-image-upload .mz-progress-indicator {
  background: linear-gradient(to right, #bbf7c5, #119408);
  height: 20px;
  min-width: 30px;
  border-radius: 6px;
  position: absolute;
  left: 10px;
  width: calc(100% - 20px) !important;
}
.mz-image-upload .mz-progress-indicator span {
  float: right;
  padding: 0 5px;
  color: #fff;
}
</style>
