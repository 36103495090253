<template>
  <div
    class="mz-grid-item"
    @click="trackClick(item, index)"
    v-html="html"
  ></div>
</template>

<script>
import HitItem from '@/mixins/hitItem.js'

export default {
  mixins: [HitItem]
}
</script>
